var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"match-height mt-2"},[_c('b-col',{attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"mt-2"},[_c('h3',{staticClass:"float-left"},[_vm._v("Fiches comptables par forfait")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"success"}},[_vm._v(" Exporter")]),_c('AddModalBsheet',{attrs:{"current-group-id":_vm.currentGroupId}})],1)])],1),_c('b-card',[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"})])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.groups,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      },"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },"pagination-options":{
        enabled: true,
        perPage: _vm.pageLength,
      },"styleClass":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'occupation_type')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.occupationVariant(props.row.occupation_type)}},[_vm._v(" "+_vm._s(_vm.occupationLabel(props.row.occupation_type))+" ")])],1):_vm._e(),(props.column.field === 'package_name')?_c('span',[_c('b-link',{on:{"click":function($event){return _vm.editPackage(props.row)}}},[_vm._v(" "+_vm._s(props.row.package_name)+" ")])],1):(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.statusVariant(props.row.status)}},[_vm._v(" "+_vm._s(_vm.statusLabel(props.row.status))+" ")])],1):(props.column.field === 'traveler_type')?_c('span',[_c('b-badge',[_vm._v(" "+_vm._s(_vm.childrenCategories(props.row.traveler_type.toLowerCase()))+" ")])],1):(props.column.field === 'action')?_c('span',[(_vm.canBeModified)?_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'group-edit-billing-sheet',
                  params: {
                    group_id: _vm.currentGroupId,
                    pack_id: props.row.package_id,
                    occup_id: props.row.occupation_type_id,
                    traveler_id: props.row.traveler_type,
                  },
                }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Modifier")])],1),(
                  props.row.status == 'NOT_STARTED' ||
                  props.row.status == 'IN_PROGRESS'
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeStatus('COMPLETED', props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckCircleIcon"}}),_c('span',[_vm._v("Marquer Complétée")])],1):_vm._e(),(
                  props.row.status == 'NOT_STARTED' ||
                  props.row.status == 'COMPLETED'
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeStatus('IN_PROGRESS', props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ActivityIcon"}}),_c('span',[_vm._v("Marquer En cours")])],1):_vm._e(),(
                  props.row.status == 'IN_PROGRESS' ||
                  props.row.status == 'COMPLETED'
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.changeStatus('NOT_STARTED', props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"XCircleIcon"}}),_c('span',[_vm._v("Marquer Non débutée")])],1):_vm._e()],1)],1):_c('span',[_c('b-button',{attrs:{"size":"sm","variant":"flat-primary","to":{
                name: 'group-edit-billing-sheet',
                params: {
                  group_id: _vm.currentGroupId,
                  pack_id: props.row.package_id,
                  occup_id: props.row.occupation_type_id,
                  traveler_id: props.row.traveler_type,
                },
              }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v("Consulter")])],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Indiquer 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5', '10', '20']},on:{"input":function (value) { return props.perPageChanged({ currentPerPage: value }); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" des "+_vm._s(props.total)+" données ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_vm._v(" > ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }